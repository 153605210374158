<template>
  <div>
    <div class="banner">
      <div class="imgs">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in imgs" :key="index">
            <img :src="item.pic" alt="" />
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
   <div class="daily" v-on:click="jump()" >
       <header class="title">每日一信</header>
       <div style="display:flex">
        <div style="float:left;margin:0px 0px 5px 20px">  <img style="width:80px;" src="../assets/img/daily1.png" /> </div>
        <div style="float:left;margin:0px 0px 5px 10px">  <img style="width:80px;" src="../assets/img/daily2.png" /> </div>
        <div style="float:left;margin:0px 0px 5px 10px">  <img style="width:80px;" src="../assets/img/daily3.png" /> </div>
        <div style="float:left;margin:0px 0px 5px 10px">  <img style="width:80px;" src="../assets/img/daily4.png" /> </div>
      </div>    
    </div>

    <div class="good">
      <van-tabs v-model="active" sticky animated swipeable>
        <van-tab v-for="tab in tabList" :title="tab.name" :name="tab.id" :key="tab.id">  
          <div ref="mvlists">
            <ul>
              <li class="data-list" v-for="(item, index) in mvList[tab.id-1]" :key="index">
                    <div>
                        <figure class="avatar">
                              <img :src="item.cover" class="img rounded-circle" />
                        </figure>
                    </div>
                 
                    <div class="detail">
                        <div class="title van-multi-ellipsis--l2">
                            {{item.name}}<i class="iconfont icon-dianzan"/>
                        </div>
                    </div>
              </li>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { getVideoList , like,dislike} from "../api/foster.js";

export default {
  data() {
    return {
      appid:"",
      key:"",

      imgs: [{pic:require("../assets/img/banner.png")},
              {pic:require("../assets/img/banner1.png")},
              {pic:require("../assets/img/banner2.png")}],

      tabList:[{name:"孕期营养",id:1},{name:"孕期心理",id:2},{name:"新生儿护理",id:3},
                {name:"宝宝睡眠",id:4},{name:"宝宝辅食",id:5},{name:"日常护理",id:6},
                {name:"儿童疾病",id:7},{name:"儿童安全",id:8},{name:"早教启蒙",id:9},
                {name:"入园准备",id:10},{name:"儿童心理",id:11},{name:"合作育儿",id:12}
      ],
      mvList: [],
      playNum: null,
      offset: 0, //偏移数量 , 用于分页
      flag: true,
      contNum: null,
      type:null,
      limit:10,
      page:null,
      order:null,
      likeList:[],
      loading: true,
      active: 0,
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   // 监听滚动事件
    //   window.addEventListener("scroll", this.handleScroll);
    // });
  },
  beforeDestroy() {
   // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    jump(){
      window.open('daily?key='+this.key+'&appid='+this.appid,'_self');
    },
    // 添加数据
    addData() {
      // 防抖
      if (this.flag) {
        this.flag = false;
       // this.offset += 5; //第五条数据后添加五条数据

        let param= {
          type:this.type,
          page:this.page,
          limit:this.limit,
          order:order
        };

        getVideoList(param).then((res) => { 
            if(res !=null && res.data != null){
              this.mvList = this.mvList.concat(res.data.list);
              this.flag = true;
            }

        });
      }
    },
    // 滚动条监听
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条偏移量
      let lis = this.$refs.mvlists.getElementsByTagName("li");
      let num = lis.length;
      if(num>3){
        let liHeight = lis[num - 3].offsetTop;
        if (scrollTop > liHeight) {
          //触发添加数据
          this.addData();
        }
      }
    },
    getVideoListFunc(type,page,limit,order){
      let param= {
          type:type,
          page:page,
          limit:limit,
          order:order
      };
      this.type = type;
      this.page = page;
      this.limit = limit;
      this.order = order;

      getVideoList(param).then((res) => {       
          if(res !=null && res.data != null){
            this.mvList = this.groupBy(res.data.list,
                function(item) {return [item.type];});
          }
      });
    },
  
    addLikeFunc(id){
      let param= {
          openid:this.openid,
          video_id:id
      };
      addLike(param).then((res) => {       
          if(res !=null && res.data != null){
          }
      });
    },
    delLikeFunc(id){
      let param= {
          openid:this.openid,
          video_id:id
      };
      delLike(param).then((res) => {       
          if(res !=null && res.data != null){
          }
      });
    },

    groupBy(array, f) {
     let groups = {};
     array.forEach(function(o) {
       let group = JSON.stringify(f(o));
       groups[group] = groups[group] || [];
       groups[group].push(o);
     });
     return Object.keys(groups).map(function(group) {
       return groups[group];
     });
   },
  },
  created() {
    let query = this.$route.query;
    this.key = query.key;
    this.appid = query.appid;
    this.getVideoListFunc(0,1,1000,"create_time");
  },
};
</script>

<style lang="less">
.banner {
  width: 100%;
  text-align: center;
  position: relative;
  &::after {
    // background-color: #d4473c;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    height: 132px;
    content: "";
    display: block;
  }
  .imgs {
    margin: auto;
    min-height: 100px;
    background: #d4473c;
    width: 96%;
    border-radius: 5px;
    .my-swipe {
      min-height: 100px;
      img {
        width: 100%;
        border-radius: 5px;
      }
    }
  }
}

.daily {
    .title {
      height: 60px;
      line-height: 60px;
      text-indent: 5px;
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
  }

.good {
  .good-header {
    background: #f9f9f9;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #d4473c;
    font-size: 16px;
    font-weight: bold;
  }
  .good-box {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .good-item {
      box-sizing: border-box;
      width: 50%;
      border-bottom: 1px solid #e9e9e9;
      padding: 10px 10px;
      img {
        display: block;
        width: 160px;
        margin: 0 auto;
      }
      .good-desc {
        text-align: center;
        font-size: 14px;
        padding: 10px 0;
        .title {
          color: #222333;
        }
        .price {
          color: #1baeae;
        }
      }
      &:nth-child(2n + 1) {
        border-right: 1px solid #e9e9e9;
      }
    }
  }
}

   .new-list{
        overflow-y: auto;
    }
    .data-list {
        padding: 10px;
        display: flex;
        justify-content: left;
        border-bottom: solid 1px #f4f4f4;
    }
    .data-list .img {
        width: 20vw;
        height: 16vw;
        margin-right: 10px;
        float:left;
    }
    .data-list .detail {
        line-height: 1.4;
        font-size: 4vw;
        flex: 1;
        float:left;
       .iconfont {
          display: inline-block;
          font-size: 16px;
          margin-left: 1vw;
          text-align: center;
          color:#d4473c;
          width: 50px;
          b {
            font-size: 12px;
          }
        }
    }

    .data-list .detail .brief {
        font-size: 3vw;
        color: #999999;
    }

  .rounded-circle{-webkit-border-radius:5%
      !important;-moz-border-radius:5%
      !important;border-radius:5%
      !important}

</style>